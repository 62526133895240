<template>
  <div class="page">
    <template v-if="!isShowDetail">
      <el-card class="box-card-left">
        <div slot="header" class="clearfix">
          <span>事件管理</span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">筛选</el-button> -->
          <el-select style="float: right; padding: 3px 0; width: 100px; top: -10px" v-model="queryForm.eventStatus"
            slot="prepend" placeholder="筛选">
            <el-option label="全部" value=""></el-option>
            <el-option label="已完结" value="1"></el-option>
            <el-option label="未完结" value="0"></el-option>
          </el-select>
        </div>
        <div>
          <el-input placeholder="请输入关键词" v-model="queryForm.searchValue" class="input-with-select"
            @keyup.enter.native="getEventPlaceListFun()">
            <el-button slot="append" icon="el-icon-search" @click="getEventPlaceListFun()"></el-button>
          </el-input>
        </div>
        <div style="margin-top: 20px">
          <el-tree :data="treedata" :props="defaultProps" @current-change="handleNodeClick"></el-tree>
        </div>
      </el-card>

      <el-card class="box-card-right">
        <div class="header">
          <div class="left-item">
            <el-select class="select-width" size="medium" v-model="crowdTypeIds" clearable placeholder="人群分类" multiple
              collapse-tags style="width:280px;">
              <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"
                :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="right-item">
            <el-button type="primary" size="small" @click="queryTable">查询</el-button>
            <el-button type="primary" size="small" @click="TBTable">同步检测</el-button>
            <el-button type="primary" size="small" @click="exportFormInfo"
              v-loading.fullscreen.lock="fullscreenLoading">导出</el-button>
          </div>
        </div>
        <div class="content">
          <el-table :data="tableData" style="width: 100%" show-summary sum-text="汇总" @row-click="dialogTableFun">
            <el-table-column prop="placeName" label="场所" width="200" align="center">
            </el-table-column>
            <el-table-column prop="crowdTypeName" label="人群分类" width="200" align="center">
            </el-table-column>
            <el-table-column prop="total" label="排查追踪" width="150" align="center">
            </el-table-column>
            <el-table-column prop="focus" label="当前集中隔离" width="150" align="center">
            </el-table-column>
            <el-table-column prop="released" label="已解除隔离" width="150" align="center">
            </el-table-column>
            <el-table-column label="第一次核酸采样" align="center">
              <el-table-column prop="collectDayOne" label="已采样" width="80" align="center">
              </el-table-column>
              <el-table-column prop="labDayOne" label="已报告" width="80" align="center">
              </el-table-column>
              <el-table-column prop="covidDayTwo" label="阳性" width="80" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="第二次核酸采样" align="center">
              <el-table-column prop="collectDayTwo" label="已采样" width="80" align="center">
              </el-table-column>
              <el-table-column prop="labDayTwo" label="已报告" width="80" align="center">
              </el-table-column>
              <el-table-column prop="covidDayTwo" label="阳性" width="80" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="第三次核酸采样" align="center">
              <el-table-column prop="collectDayThree" label="已采样" width="80" align="center">
              </el-table-column>
              <el-table-column prop="labDayThree" label="已报告" width="80" align="center">
              </el-table-column>
              <el-table-column prop="covidDayThree" label="阳性" width="80" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="第四次核酸采样" align="center">
              <el-table-column prop="collectDayFour" label="已采样" width="80" align="center">
              </el-table-column>
              <el-table-column prop="labDayFour" label="已报告" width="80" align="center">
              </el-table-column>
              <el-table-column prop="covidDayFour" label="阳性" width="80" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="第五次核酸采样" align="center">
              <el-table-column prop="collectDayFive" label="已采样" width="80" align="center">
              </el-table-column>
              <el-table-column prop="labDayFive" label="已报告" width="80" align="center">
              </el-table-column>
              <el-table-column prop="covidDayFive" label="阳性" width="80" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="第六次核酸采样" align="center">
              <el-table-column prop="collectDaySix" label="已采样" width="80" align="center">
              </el-table-column>
              <el-table-column prop="labDaySix" label="已报告" width="80" align="center">
              </el-table-column>
              <el-table-column prop="covidDaySix" label="阳性" width="80" align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column label="第七次核酸采样" align="center">
              <el-table-column prop="collectDaySeven" label="已采样" width="80" align="center">
              </el-table-column>
              <el-table-column prop="labDaySeven" label="已报告" width="80" align="center">
              </el-table-column>
              <el-table-column prop="covidDaySeven" label="阳性" width="80" align="center">
              </el-table-column>
            </el-table-column>
          </el-table>
          <!-- <el-table :data="tableData2" style="width: 100%" >
          <el-table-column
            align="right">
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="search"
                size="mini"
                placeholder="输入关键字搜索"/>
            </template>
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">Edit</el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table> -->
        </div>
      </el-card>
    </template>

    <el-dialog title="居民信息" :visible.sync="dialogTableVisible">
      <el-table :data="gridData" height="600">
        <el-table-column property="name" label="姓名"></el-table-column>
        <el-table-column property="cardNo" label="证件号码"></el-table-column>
        <el-table-column property="isolationPointName" label="隔离点"></el-table-column>
        <el-table-column property="crowdTypeName" label="人群分类"></el-table-column>
        <el-table-column property="personStatus" label="状态">
          <template slot-scope="scope">
            <el-tag size="small" type="danger">{{ scope.row.personStatus | personStatusFilter }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column property="address" label="操作">
          <template slot-scope="{ row }">
            <el-link type="primary" @click="userDetails(row)">查看详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
  </div>
</template>

<script type="text/javascript">
import {
  getEventPlaceList,
  getEventPlaceReport,
  exportEventPlaceReport,
  userListQuery
} from "../../api/Statistics/index.js";
import {
  pullCovidResult
} from '../../api/QuarantineRegistration/quarantineRegistrationpop';
import { importFile, messageBox } from "../../utils/utils";
import { mapState } from "vuex";
import residentInformation from "../QuarantineRegistration/residentInformation";
import { PERSON_STATUS } from "@/common/constants";


export default {
  name: "page",
  data() {
    return {
      queryForm: {
        searchValue: "",
        eventStatus: "",
      },
      crowdTypeIds: "",
      fullscreenLoading: false,
      treedata: [],
      defaultProps: {
        children: "placeListList",
        label: "placeName",
      },
      tableParams: {},
      tableData: [],
      tableData2: [],
      dialogTableVisible: false,
      gridData: [],
      isShowDetail: false,
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
    };
  },
  components: {
    residentInformation
  },
  computed: {
    ...mapState({
      typepopSelect: (state) => state.user.typepopSelect, // 人群分类
    }),
  },
  filters: {
    personStatusFilter: function (value) {
      if (!value) return "";
      const personStatus = PERSON_STATUS.find(item => {
        return item.value == value;
      });
      return personStatus ? personStatus.label : "";
    },
  },
  watch: {
  },
  created() {
    this.getEventPlaceListFun();
  },
  methods: {
    getEventPlaceListFun() {
      let params = Object.assign({}, this.queryForm);
      getEventPlaceList({ params }).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.treedata = data.map((item) => {
            return Object.assign({ placeName: item.eventName }, item);
          });
        }
      });
    },
    queryTable() {
      let params = Object.assign(
        { crowdTypeIds: this.crowdTypeIds },
        this.tableParams
      );
      if (!params.eventId || params.eventId == '') {
        this.$message({
          type: 'error',
          message: '请选择事件!'
        });
        return
      }
      getEventPlaceReport(params).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.tableData = data.data;
          this.tableData2 = data.summary;
        }
      });
    },
    TBTable() {
      pullCovidResult().then(res => {
        this.$message.success('数据已同步');
        // this.queryTable()
      })
    },
    exportFormInfo() {
      let params = Object.assign(
        {
          crowdTypeIds: this.crowdTypeIds,
          hospitalId: this.userdoctor.hospitalId,
          orgCode: this.userdoctor.orgCode
        },
        this.tableParams
      );
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportEventPlaceReport(params).then((res) => {
            this.fullscreenLoading = false;
            importFile(res.data, "疫情排查检测管控统计");
          });
        },
        "warning",
        "是否确认导出 疫情排查检测管控统计 数据？"
      );
    },
    handleNodeClick(data, node) {
      let eventId, placeId;
      if (node.level === 1) {
        eventId = data.id;
        placeId = '';
      } else if (node.level === 2) {
        eventId = node.parent.data.id;
        placeId = data.placeId;
      }
      this.tableParams = { eventId, placeId };
      this.queryTable(this.tableParams);
    },
    dialogTableFun(row) {
      this.dialogTableVisible = true;
      let params = { eventId: this.tableParams.eventId, crowdType: row.crowdType, placeId: row.placeId, collectStatus: '', labResult: '', status: '', times: '' };
      userListQuery({ params }).then((res) => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.gridData = data;
        }
      });
    },
    userDetails(row) {
      this.dialogTableVisible = false;
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  background: rgba(0, 0, 0, 0);
  display: flex;
}

.box-card-left {
  width: 280px;
  overflow: auto;
}

.box-card-right {
  flex: 1;
  margin-left: 10px;
  overflow: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  margin-top: 20px;
}
</style>